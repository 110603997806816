import React from 'react';
import './PokemonGrid.css'; 
import { Link } from 'react-router-dom';

//helper function to capitalize the first letter of each pokemon name
const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const PokemonGrid = ({ pokemonData }) => {
  return (
    <div className="pokemon-grid">
      {pokemonData.map((pokemon) => (
        <Link key={pokemon.id} to={`/pokemon/${pokemon.id}`} className="pokemon-card">
          <div className="pokemon-card-content">
            <img src={pokemon.sprites.front_default} alt={pokemon.name} />
            <h3>{capitalizeFirstLetter(pokemon.name)}</h3> 
            <h4>{pokemon.id}</h4> {/* Display Pokedex number */}
            <h4>Type: {pokemon.types.map((type) => capitalizeFirstLetter(type.type.name)).join(', ')}</h4>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default PokemonGrid;